@import "../style.scss";

#gallery {
  @include flex(0, 0, $vl_grey, 0, 0);
}

.g-icon {
  font-size: 6rem;
  color: $d_grey;
}
.photos {
  @include flex(row, center, 0, wrap, center);
  img {
    width: 100%;
    height: 100%;
  }
}
.image {
  margin: 2rem;
}
.g-content-visible {
  @include flexChild(1 1 100%, column, 0, 0, 0);
}

.g-content-hidden {
  @include flexChild(1 1 75%, column, 0, 0, 0);
}

.g-nav-visible {
  @include flexChild(1 1 25%, column, 0, 0, white);
}

.g-nav-hidden {
  display: none;
}
