@import "../style.scss";

#home {
  @include flex(0, stretch);
}

.h-icon {
  font-size: 6rem;
  color: $d_grey;
}

.h-name {
  @include txtDecoration(2rem, bold);
  text-align: center;
  letter-spacing: 1rem;
  margin: 2rem;
//  color: $d_grey;

  .h-first {
    animation: slideInRight 2s ease-out;
    //font-size: 4rem;
    color: $pink;
    @media (max-width: 35rem) {
      font-size: 3rem;
    }
  }

  .h-last {
    animation: slideInLeft 2s ease-out;

    color: $d_grey;
    @media (max-width: 35rem) {
      font-size: 3rem;
    }
  }
}

.h-content-visible {
  @include flexChild(1 1 100%, column, space-between, 0, $vl_grey);
}

.h-content-hidden {
  @include flexChild(1 1 100%, column, space-between, 0, $vl_grey);
}

.h-nav-visible {
  @include flexChild(1 1 25%, column);
}

.h-nav-hidden {
  display: none;
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(-6rem);
  }

  50% {
    opacity: 0.5;
    transform: translateX(0.5rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(6rem);
  }

  50% {
    opacity: 0.5;
    transform: translateX(-0.5rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
