$d_grey: rgba(48, 48, 48);
$m_grey: rgba(74, 74, 74, 0.8);
$l_grey: rgba(145, 145, 145);
$vl_grey: rgba(245, 245, 245);
$l_orange: rgba(255, 149, 117);
$b_blue: rgba(161, 193, 255);
$pink: rgba(237, 26, 153, 0.9);

@mixin linkDecoration($size, $weight) {
  color: $m_grey;
  font-size: $size;
  font-weight: $weight;
  text-decoration: none;

  &:hover {
    color: $d_grey;
    text-decoration: none;
  }
}

@mixin txtDecoration($size, $weight: normal) {
  font-size: $size;
  font-weight: $weight;
  font-family: inherit;
}

@mixin flex($direction: row,
  $justify: flex-start,
  $color: white,
  $wrap: nowrap,
  $align: normal) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
  background-color: $color;
  min-width: fit-content;
}

@mixin flexChild($flex,
  $direction: row,
  $justify: flex-start,
  $align: normal,
  $color: white) {
  flex: $flex;
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  background-color: $color;
  min-height: 100vh;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  @media (max-width: 45rem) {
    font-size: 40%;
  }
}

body {
  font-family: "Courier New", Courier, monospace;
  font-size: 1rem;
}

a,
a:active {
  @include linkDecoration(2.5rem, bold);
}

h2 {
  color: $m_grey;
  @include txtDecoration(2rem, bold);
}
